import { GET_ACCOUNTS_DATA, GET_AGENTS_LIST } from './constans';
import { IAccountData, IAcountDataAction } from './types';

const initialState = {
  fullName: '',
  company: '',
  title: '',
  photo: '',
  agentsArray: [],
  isAreaManager: 0,
  userType: '',
  memberId: 0,
  email: '',
  uuid: '',
  headerInformation: {
    logo: '',
    name: '',
  },
};

export function AppReducer(state: IAccountData = initialState, action: IAcountDataAction) {
  switch (action.type) {
    case GET_ACCOUNTS_DATA: {
      const {
        fullName,
        company,
        title,
        photo,
        agentsArray,
        isAreaManager,
        userType,
        memberId,
        uuid,
        headerInformation,
        email,
        cdate,
        isReseller,
        parentMemberId,
        billingType,
        orderCount,
        lastOrderDate,
        subscriberYN,
        uppermostParentId,
        preferredSpId,
        xloginbyid,
        redirectOld,
        hasOrders,
        showTwistTransitionAgreementModal
      } = action.payload;

      return {
        fullName,
        company,
        title,
        photo,
        agentsArray,
        isAreaManager,
        userType,
        memberId,
        uuid,
        headerInformation,
        email,
        cdate,
        isReseller,
        parentMemberId,
        billingType,
        orderCount,
        lastOrderDate,
        subscriberYN,
        uppermostParentId,
        preferredSpId,
        xloginbyid,
        redirectOld,
        hasOrders,
        showTwistTransitionAgreementModal
      };
    }
    case GET_AGENTS_LIST: {
      return {
        ...state,
        agentsArray: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}
