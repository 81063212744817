import React, { useEffect, useState } from 'react';
import Header from './Header';

import RequestServices from '../helpers/requestServices';
import Photo from './Photo';
import MenuComponent from '../Story/Details/MenuComponent';
import Links from '../Story/Media/Links';
import { FloorPlan } from './FloorPlan';
import { CustomVideo } from './CustomVideo';
import { Documents } from './Documents';
import { Matterport } from './Matterport';
import { WalkthroughVideo } from './WalkthroughVideo';
import { Flyer } from './Flyer';
import { Call2Action } from './Call2Action';
import Loading from '../helpers/Components/Loading';

import styles from './Share.module.scss';

const api = new RequestServices({});

const menu = [
  { key: 'photo', title: 'Photo' },
  { key: 'links', title: 'Presentation Links' },
  { key: 'plans', title: 'Floor Plan' },
  { key: 'customVideo', title: 'Custom Video' },
  { key: 'documents', title: 'Documents' },
  { key: '360walk', title: '3D Walkthrough' },
  { key: 'walkVideo', title: 'Slideshow Video' },
  // { key: "smVideo", title: "SM Video" },
  { key: 'flayer', title: 'Flyers' },
  { key: 'call2Action', title: 'Call2Action Photo' },
];

function Share(props: any) {
  useEffect(() => {
    getMedia();
    document.title = 'Marketing Center - HD Pro';
    setTimeout(() => {
      const hash = window.location.hash.split('#')[1];
      const element = document.getElementById(hash);
      element && element.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  }, []);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    photo: [],
    flyers: [],
    links: [],
    plans: [],
    customVideo: [],
    documents: [],
    walkthroughVideo: [],
    matterport: [],
    call2Action: [],
    address: '',
    mlsID: '',
    frontPhoto: '',
    availableFloorPlans: false,
    auth: false,
    id: 0
  });
  const uuid = props.match.params.uuid;
  const getMedia = async () => {
    const { data } = await api.getMedia(uuid, 'share');
    const map = data.data.photo.map((el: any, index: number) => {
      return {
        id: el.id,
        url: `${el.img_url}`,
        status: 'done',
      };
    });

    setData({
      photo: map,
      flyers: data.data.flyer,
      links: data.data.presentation_links,
      plans: data.data.floorplan.data,
      customVideo: data.data.custom_videos,
      documents: data.data.documents,
      walkthroughVideo: data.data.walkthru_video,
      matterport: data.data['3D_walkthru'],
      call2Action: data.data.call2action_photo,
      address: data.data.address,
      mlsID: data.data.mls_id,
      frontPhoto: data.data.front_photo,
      availableFloorPlans: data.data.floorplan_create_available,
      auth: data.data.auth,
      id: data.data.order_id
    });
    setLoading(false);
  };

  const filterMenu = () => {
    let resMenu = menu;
    if (!data.availableFloorPlans && !plans.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== 'plans';
      });
    }
    if (!flyers.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== 'flayer';
      });
    }

    if (!customVideo.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== 'customVideo';
      });
    }

    if (!documents.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== 'documents';
      });
    }

    if (!walkthroughVideo.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== 'walkVideo';
      });
    }

    if (!matterport.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== '360walk';
      });
    }

    if (!call2Action.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== 'call2Action';
      });
    }

    if (!links.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== 'links';
      });
    }
    if (!photo.length) {
      resMenu = resMenu.filter((el) => {
        return el.key !== 'photo';
      });
    }

    return resMenu;
  };
  const {
    address,
    mlsID,
    frontPhoto,
    photo,
    links,
    plans,
    customVideo,
    documents,
    matterport,
    walkthroughVideo,
    flyers,
    call2Action,
    auth,
    id
  } = data;
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Header auth={auth} uuid={uuid} frontPhoto={frontPhoto} address={address} mlsID={mlsID} />
      <div className="content-wrapper">
        <MenuComponent items={filterMenu()} />
        <div className="card-wrapper">
          {!!photo.length && <Photo uuid={uuid} photo={photo} id={id} />}
          {!!links.length && <Links links={links} uuid={uuid} />}
          {!!plans.length && <FloorPlan uuid={uuid} plans={plans} />}
          {!!customVideo.length && <CustomVideo uuid={uuid} customVideo={customVideo} />}
          {!!documents.length && <Documents uuid={uuid} documents={documents} />}
          {!!matterport.length && <Matterport matterport={matterport} />}
          {!!walkthroughVideo.length && <WalkthroughVideo id={mlsID} video={walkthroughVideo} />}
          {!!flyers.length && <Flyer uuid={uuid} flyers={flyers} />}
          {!!call2Action.length && <Call2Action uuid={uuid} call2Action={call2Action} />}
        </div>
      </div>
    </div>
  );
}

export default Share;
