import RequestServices from "../helpers/requestServices";

import { GET_ACCOUNTS_DATA, GET_AGENTS_LIST } from "./constans";
import { DispatchType } from "./types";

const api = new RequestServices({});

export function getAccountsData(dispatch: DispatchType) {
  return async () => {
    const { data: { data = {} } = {} } = await api.getAccountsData();
    // const { data: agentsData = {} } = await api.getSubAccouts();

    dispatch({
      type: GET_ACCOUNTS_DATA,
      payload: {
        fullName: data.account.contact_person,
        company: data.account.company,
        title: data.account.title,
        photo: data.account.photo,
        // agentsArray: [data.account, ...agentsData.data.items],
        isAreaManager: data.account.is_area_manager,
        userType: data.userType,
        memberId: data.account.member_id,
        uuid: data.account.uuid_text,
        headerInformation: data.account.header_information,
        email: data.account.email,
        cdate: data.account.cdate,
        isReseller: !!data.account.is_reseller,
        parentMemberId: data.account.parent_member_id,
        billingType: data.account.billing_type,
        orderCount: data.account.order_count,
        lastOrderDate: data.account.last_order_date,
        subscriberYN: data.account.subscriber_yn,
        uppermostParentId: data.account.uppermost_parent_id,
        preferredSpId: data.account.preferred_sp_id,
        xloginbyid: data.account.xloginbyid,
        redirectOld: data.account.redirect_old,
        hasOrders: data.account.hasOrders,
        showTwistTransitionAgreementModal: data.account.showTwistTransitionAgreementModal,
      },
    });
  };
}

export function getAgentsList(dispatch: DispatchType) {
  return async () => {
    const { data: { data = {} } = {} } = await api.getAccountsData();
    const { data: agentsData = {} } = await api.getSubAccouts();
    
    dispatch({
      type: GET_AGENTS_LIST,
      payload: [data.account, ...agentsData.data.items]
    });
  };
}
