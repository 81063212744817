import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App/AppContainer';
import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import 'leaflet/dist/leaflet.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { Route, Router } from 'react-router';
import { Spin } from 'antd';
import SignInComponent from './Authorization/SignIn/SignInComponent';
import ForgotPasswordComponent from './Authorization/SignIn/ForgotPassword/ForgotPasswordComponent';
import ResetPasswordComponent from './Authorization/SignIn/ForgotPassword/ResetPasswordComponent';
import RegistrationContainer from './Authorization/Registration/RegistrationContainer';
import Share from './Share/Share';
import middleware from './middleware';
import HotspotsComponent from './Hotspots/HotspotsComponent';
const history = createBrowserHistory({});

interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}

const composeEnhancer: typeof compose =
  (window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store: any = createStore(
  rootReducer(history),
  composeEnhancer(applyMiddleware(routerMiddleware(history), thunk, middleware)),
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router history={history}>
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Spin />
            </div>
          }
        >
          <Route
            exact
            path={[
              '/',
              '/property/:type',
              '/property/:type/:type2',
              '/diary',
              '/property',
              '/diary/:type/:type2/:type3',
              '/diary/:type/',
              '/diary/:type/:type2',
              '/profile-settings',
              '/member-invoice',
              // '/hotspots/:id'
            ]}
            component={App}
          />

          <Route
                  path="/hotspots/:id"
                  component={HotspotsComponent}
                />
          <Route path="/signin" component={SignInComponent} />
          <Route path="/registration" component={RegistrationContainer} />
          <Route path="/forgot" exact component={ForgotPasswordComponent} />
          <Route path="/forgot/:code" component={ResetPasswordComponent} />
          <Route path="/media/:uuid" component={Share} />
        </Suspense>
      </Router>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);
//
// (function(h,o,t,j,a,r){        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
//     h._hjSettings={hjid:process.env.REACT_APP_HJ_ID,hjsv:6};        a=o.getElementsByTagName('head')[0];
//     r=o.createElement('script');r.async=1;        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
//     a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
